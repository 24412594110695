import { Card, CircularProgress, Grid, Typography } from '@mui/material';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { observer } from 'mobx-react-lite';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useMemo } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { T4InfoIcon } from 'shared/components/T4InfoIcon';
import { formatReadDate } from 'shared/utilities/dateUtilities';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';
import { NotesField } from '../_components/notesField';
import { ProjectedTransactionsGrid } from '../_components/projectedTransactionsGrid';
import { ReportedTransactionsGrid } from '../_components/reportedTransactionsGrid';
import { SelectedTotalAlert } from '../_components/selectedTotal';
import { UnreconciledAmountAlert } from '../_components/unreconciledAmount';
import {
	convertToPercentage,
	normalizeReconciliationStatus,
} from '../_hooks/useReconciliationCalculations';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type ReconciliationViewProps = {
	reconciliation: string | Reconciliation | undefined;
};

export const ReconciliationView: FC<ReconciliationViewProps> = observer(
	({ reconciliation: _reconciliation }) => {
		const {
			projectedTransactionsQueryContext: {
				loading: projectedTransactionsLoading,
			},
			reportedTransactionsQueryContext: {
				isLoading: reportedTransactionsLoading,
			},
			getSingleReconciliationQuery: { isFetching: reconciliationIsFetching },
			reconciliation,
		} = useReconciliationsContext();

		const transactionCount = useMemo(() => {
			return reconciliation
				? reconciliation.summary.projectedCount +
						reconciliation.summary.reportedCount
				: 0;
		}, [reconciliation]);

		const reportedTransactions = useMemo(() => {
			return reconciliation?.reportedTransactions ?? [];
		}, [reconciliation?.reportedTransactions]);

		const projectedTransactions = useMemo(() => {
			return reconciliation?.projectedTransactions ?? [];
		}, [reconciliation?.projectedTransactions]);

		const loading = useMemo(() => {
			return (
				projectedTransactionsLoading ||
				reportedTransactionsLoading ||
				reconciliationIsFetching
			);
		}, [
			projectedTransactionsLoading,
			reportedTransactionsLoading,
			reconciliationIsFetching,
		]);

		const [
			reportedCurrencySource,
			reportedCurrencyCode,
			reportedReportingCurrencyCode,
		] = useMemo(() => {
			let source = CurrencySource.Account;
			let accountCurrency = defaultCurrencyCode;
			let reportingCurrency = defaultCurrencyCode;
			const codes = new Set<string>();
			reportedTransactions.forEach((rt) => {
				codes.add(rt.number.accountCurrencyCode);
				if (
					rt.number.accountCurrencyCode &&
					rt.number.accountCurrencyCode !== accountCurrency
				) {
					accountCurrency = rt.number.accountCurrencyCode;
				}
				if (
					rt.number.reportingCurrencyCode &&
					rt.number.reportingCurrencyCode !== reportingCurrency
				) {
					reportingCurrency = rt.number.reportingCurrencyCode;
				}
			});
			if (codes.size >= 2) {
				source = CurrencySource.Reporting;
			}
			return [
				source,
				source === CurrencySource.Account ? accountCurrency : reportingCurrency,
				reportingCurrency,
			];
		}, [reportedTransactions]);

		const [projectedCurrencySource, projectedCurrencyCode] = useMemo(() => {
			let source = CurrencySource.Account;
			let accountCurrency = defaultCurrencyCode;
			let reportingCurrency = defaultCurrencyCode;
			const codes = new Set<string>();
			projectedTransactions.forEach((rt) => {
				codes.add(rt.amount.accountCurrencyCode);
				if (
					rt.amount.accountCurrencyCode &&
					rt.amount.accountCurrencyCode !== accountCurrency
				) {
					accountCurrency = rt.amount.accountCurrencyCode;
				}
				if (
					rt.amount.reportingCurrencyCode &&
					rt.amount.reportingCurrencyCode !== reportingCurrency
				) {
					reportingCurrency = rt.amount.reportingCurrencyCode;
				}
			});
			if (codes.size >= 2) {
				source = CurrencySource.Reporting;
			}
			return [
				source,
				source === CurrencySource.Account ? accountCurrency : reportingCurrency,
			];
		}, [projectedTransactions]);

		const [displayedCurrencySource, displayedCurrencyCode] = useMemo(() => {
			const source =
				reportedCurrencySource === projectedCurrencySource &&
				reportedCurrencyCode === projectedCurrencyCode
					? reportedCurrencySource
					: CurrencySource.Reporting;

			const code =
				reportedCurrencySource === projectedCurrencySource &&
				reportedCurrencyCode === projectedCurrencyCode
					? reportedCurrencyCode
					: reportedReportingCurrencyCode;

			return [source, code];
		}, [
			reportedCurrencySource,
			reportedCurrencyCode,
			projectedCurrencySource,
			projectedCurrencyCode,
			reportedReportingCurrencyCode,
		]);

		const projectedAmount = useMemo(() => {
			let result = 0;
			projectedTransactions.forEach((rt) => {
				const reportingAmount =
					rt.amount.reportingCurrencyAmount !== undefined
						? rt.amount.reportingCurrencyAmount
						: rt.amount.accountCurrencyAmount === 0
						? 0
						: NaN;
				result +=
					displayedCurrencySource === CurrencySource.Reporting
						? reportingAmount
						: rt.amount.accountCurrencyAmount;
			});
			return result;
		}, [projectedTransactions, displayedCurrencySource]);

		const isProjectedAmountValid = useMemo(() => {
			return !isNaN(projectedAmount);
		}, [projectedAmount]);

		const reportedAmount = useMemo(() => {
			let result = 0;
			reportedTransactions.forEach((rt) => {
				const reportingAmount =
					rt.number.reportingCurrencyAmount !== undefined
						? rt.number.reportingCurrencyAmount
						: rt.number.accountCurrencyAmount === 0
						? 0
						: NaN;
				result +=
					displayedCurrencySource === CurrencySource.Reporting
						? reportingAmount
						: rt.number.accountCurrencyAmount;
			});
			return result;
		}, [reportedTransactions, displayedCurrencySource]);

		const isReportedAmountValid = useMemo(() => {
			return !isNaN(reportedAmount);
		}, [reportedAmount]);

		const unreconciledAmount = useMemo(() => {
			return projectedAmount - reportedAmount;
		}, [projectedAmount, reportedAmount]);

		const isUnreconciledAmountValid = useMemo(() => {
			return !isNaN(unreconciledAmount);
		}, [unreconciledAmount]);

		const unreconciledVariance = useMemo(() => {
			if (unreconciledAmount === 0) {
				return 0;
			} else if (projectedAmount === 0) {
				return 1;
			} else {
				return unreconciledAmount / projectedAmount;
			}
		}, [unreconciledAmount, projectedAmount]);

		const isUnreconciledVarianceValid = useMemo(() => {
			return !isNaN(unreconciledVariance);
		}, [unreconciledVariance]);

		const loadingGrid = useMemo(() => {
			return (
				<Grid
					sx={{
						height: 'inherit',
						width: 'inherit',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</Grid>
			);
		}, []);

		const reported = useMemo(() => {
			if (reportedTransactionsLoading || reconciliationIsFetching) {
				return loadingGrid;
			} else {
				return (
					<>
						<Grid item container xs={true}>
							<ActuallyPrettyGoodDataGridWrapper>
								<ReportedTransactionsGrid
									stonlyId="reported-items-view"
									tableKey="reported-items-view"
									reportedTransactions={reportedTransactions}
								/>
							</ActuallyPrettyGoodDataGridWrapper>
						</Grid>
						<Grid item xs="auto">
							<SelectedTotalAlert
								amount={reportedAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isReportedAmountValid}
							/>
						</Grid>
					</>
				);
			}
		}, [
			displayedCurrencyCode,
			displayedCurrencySource,
			isReportedAmountValid,
			loadingGrid,
			reconciliationIsFetching,
			reportedAmount,
			reportedTransactions,
			reportedTransactionsLoading,
		]);

		const projected = useMemo(() => {
			if (projectedTransactionsLoading || reconciliationIsFetching) {
				return loadingGrid;
			} else {
				return (
					<>
						<Grid item container xs={true}>
							<ActuallyPrettyGoodDataGridWrapper>
								<ProjectedTransactionsGrid
									stonlyId="projected-items-view"
									tableKey="projected-items-view"
									projectedTransactions={projectedTransactions}
								/>
							</ActuallyPrettyGoodDataGridWrapper>
						</Grid>
						<Grid item xs="auto">
							<SelectedTotalAlert
								amount={projectedAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isProjectedAmountValid}
							/>
						</Grid>
					</>
				);
			}
		}, [
			displayedCurrencyCode,
			displayedCurrencySource,
			isProjectedAmountValid,
			loadingGrid,
			projectedAmount,
			projectedTransactions,
			projectedTransactionsLoading,
			reconciliationIsFetching,
		]);

		const reconciliationInfo = useMemo(() => {
			if (loading) {
				return loadingGrid;
			} else {
				return (
					<>
						<Grid item xs={12}>
							<UnreconciledAmountAlert
								amount={unreconciledAmount}
								currencyCode={displayedCurrencyCode}
								currencySource={displayedCurrencySource}
								isAmountValid={isUnreconciledAmountValid}
								variance={unreconciledVariance}
							/>
						</Grid>

						{/* Notes */}
						<Grid item xs={12}>
							<T4InfoIcon
								title="Reconciliation Status"
								description="Reconciliation status is automatically determined by the variance percentage. If the variance falls within an acceptable range, you can manually update the status to mark it as reconciled, indicating that no future transactions are expected."
							>
								<T4Checkbox
									label={'Manually Reconciled'}
									disabled
									checked={reconciliation?.manuallyReconciled ?? false}
								/>
							</T4InfoIcon>
						</Grid>
						<Grid item xs={12}>
							<NotesField defaultValue={reconciliation?.notes} disabled />
						</Grid>
					</>
				);
			}
		}, [
			displayedCurrencyCode,
			displayedCurrencySource,
			isUnreconciledAmountValid,
			loading,
			loadingGrid,
			reconciliation?.manuallyReconciled,
			reconciliation?.notes,
			unreconciledAmount,
			unreconciledVariance,
		]);

		const reconcilationHeader = useMemo(() => {
			if (reconciliationIsFetching) {
				return (
					<>
						<Grid
							sx={{
								height: 'inherit',
								width: 'inherit',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CircularProgress color="info" />
						</Grid>
					</>
				);
			} else {
				return (
					<>
						<Grid item xs={12}>
							<Typography variant="h3" align="center" sx={{ color: 'white' }}>
								{reconciliation
									? normalizeReconciliationStatus(reconciliation.status)
									: 'Unknown Status'}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align="center" sx={{ color: 'white' }}>{`${
								isUnreconciledVarianceValid
									? convertToPercentage(unreconciledVariance)
									: '%'
							} variance | ${transactionCount} associated transactions | ${formatReadDate(
								reconciliation?.createdOn,
							)}`}</Typography>
						</Grid>
					</>
				);
			}
		}, [
			isUnreconciledVarianceValid,
			reconciliation,
			reconciliationIsFetching,
			transactionCount,
			unreconciledVariance,
		]);

		return (
			<Grid container item xs={12} spacing={1}>
				{/* Blue Title Banner */}
				<Grid item xs={12}>
					<Card
						sx={(theme) => ({
							backgroundColor: theme.palette.secondary.main,
						})}
					>
						<Grid container sx={{ gap: 2, padding: '1rem' }}>
							{reconcilationHeader}
						</Grid>
					</Card>
				</Grid>

				{/* Reported Transactions Grid */}
				<Grid container item lg={6} md={12} sx={{ minHeight: 500 }}>
					<Grid
						container
						item
						direction="column"
						flexWrap="nowrap"
						sx={{
							height: '100%',
							gap: 2,
						}}
					>
						<Grid item>
							<Typography variant="h4">Reported</Typography>
						</Grid>
						{reported}
					</Grid>
				</Grid>

				{/* Projected Transactions Grid */}
				<Grid container item lg={6} md={12} sx={{ minHeight: 500 }}>
					<Grid
						container
						item
						direction="column"
						flexWrap="nowrap"
						sx={{
							height: '100%',
							gap: 2,
						}}
					>
						<Grid item>
							<Typography variant="h4">Projected</Typography>
						</Grid>
						{projected}
					</Grid>
				</Grid>

				{/* Unreconciled Amount Banner */}
				{reconciliationInfo}
			</Grid>
		);
	},
);
